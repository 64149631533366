import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import Img from 'gatsby-image';

export default function Template({ data }) {
  const {
    statementOfFaithContent,
    statementOfFaithImage,
    statementOfFaithQuote,
    statementOfFaithTitle
  } = data.allContentfulPageStatementOfFaith.edges[0].node;

  return (
    <Layout nav="colored-nav">
      <HelmetComponent
        title={statementOfFaithTitle}
        description={statementOfFaithContent}
        image={statementOfFaithImage.fluid.src}
      />
      <section id="section-missionvision" className="two-columns mt80">
        <div className="container mt30">
          <div className="two-columns-content row">
            <div className="col-md-6 col-sm-12">
              <Img className="w-100" fluid={statementOfFaithImage.fluid} alt="" />
            </div>
            <div className="col-md-5 col-sm-12">
              <h2>{statementOfFaithTitle}</h2>
              <p className="mt30">{statementOfFaithContent.statementOfFaithContent}</p>
            </div>
          </div>
        </div>
      </section>

      <section id="section-quote" className="col-sm-12">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="quote-h2"
                dangerouslySetInnerHTML={{ __html: statementOfFaithQuote.childMarkdownRemark.html }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const faithPageQuery = graphql`
  query FaithPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
    allContentfulPageStatementOfFaith {
      edges {
        node {
          statementOfFaithContent {
            statementOfFaithContent
          }
          statementOfFaithImage {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          statementOfFaithQuote {
            statementOfFaithQuote
            childMarkdownRemark {
              html
            }
          }
          statementOfFaithTitle
        }
      }
    }
  }
`;
